import React, { useState, useEffect }  from 'react';
import { withRouter } from "react-router-dom";
import { Tag, Select, Button, Input, Form, Modal, List, Image } from 'antd';
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  LinkOutlined
} from '@ant-design/icons';
import Vimeo from '@u-wave/react-vimeo';

import Loader from './Loader.js';

const { Option } = Select;

const UrlEdit = (props) => {

  const add = props.add;
  const pid36 = props.id;
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  const [vimeoID, setVimeoID] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [generatingURL, setGeneratingURL] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  const [videos, setVideos] = useState(false);
  const [videoList, setVideoList] = useState();
  const [videosTotal, setVideosTotal] = useState(null);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);



  const generateURL = (e) => {
    setGeneratingURL(true);
    fetch("/api/urls/generate").then(res => res.json()).then(res => {
      form.setFieldsValue({
        or_url: res.url
      });
      setGeneratingURL(false);
    });
  };


  const cancelAdd = () => {
    props.history.push("/urls");
  };


  const onSubmitFailed = () => {
    console.log("Submit failed. Values invalid?");
    setSubmitting(false);
  };


  const onSubmit = (values) => {
    if (pid36) {
      fetch('/api/urls/update/' + pid36, {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(values)
      }).then(res => res.json()).then(data => {
        setSubmitting(false);
        props.history.push("/urls");
      });
    }
    else if (add) {
      fetch('/api/urls/add', {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(values)
      }).then(res => res.json()).then(data => {
        setSubmitting(false);
        props.history.push("/urls");
      });
    }
  };


  const onSelectVimeoVideoChange = (uri) => {

    const _vimeoID = uri.split("/").pop();
    setVimeoID(_vimeoID);

    fetch("/api/vimeo/videos/" + _vimeoID).then(res => res.json()).then(res => {
      console.log(res);
      form.setFieldsValue({
        name : res.name,
        thumbnail_url: res.pictures.sizes[3].link,
        vimeo_url: res.link
      });
      const _d = {
        tags: res.tags,
        description: res.description,
        name: res.name,
        vimeo_url: res.link
      };
      setData({data, ..._d});
    });
  };


  const selectVideoModal = () => {
    setVideoModalVisible(true);
  }

  const refreshVideoData = () => {
    onSelectVimeoVideoChange(data.uri);
  }

  const onFormChange = ({ layout }) => {
  };


  useEffect(() => {

    const populateVimeoVideosDropdown = (callback) => {
      fetch("/api/vimeo/videos").then(res => res.json()).then(res => {
        setVideos(res.data.map(d => <Option key={d.link}>{d.name}</Option>));
        if (callback) callback();
      });
    }

    if (pid36) {
      fetch("/api/urls/get/" + pid36).then(res => res.json()).then(data => {
        const _vimeoID = data.vimeo_url.split("/").pop();
        setVimeoID(_vimeoID);

        fetch("/api/vimeo/videos/" + _vimeoID).then(res => res.json()).then(res => {

          const _combine = { ...data, ...res };

          _combine.name = data.name;

          setData(_combine);

          populateVimeoVideosDropdown(() => {
            form.setFieldsValue({
              vimeoVideo: _combine.vimeo_url
            });
          });

        });

      });
    }
    else if (add) {
      const defaultData = {
        quality: "4k",
        tags: [],
        vimeo_url : "",
        owner: "CFire"
      }
      setData(defaultData);
      populateVimeoVideosDropdown(() => {
        form.setFieldsValue({
          vimeoVideo: "Select a Video"
        });
      });
      generateURL();
    }


    fetch("/api/vimeo/showcase").then(res => res.json()).then(showcase => {
      setVideosTotal(showcase.metadata.connections.videos.total);
      fetch("/api/vimeo/videos").then(res => res.json()).then(res => {
        setVideoList(res.data);
        setInitLoading(false);
      });

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onCancel = () => {
    setVideoModalVisible(false);
  }

  const onLoadMore = () => {
    if (videoList.length < videosTotal) {
      setLoading(true);
      setInitLoading(true);
      setPage(page + 1);
      fetch("/api/vimeo/videos?page=" + (page + 1))
      .then(res => res.json()).then(res => {
        const l = videoList.concat(res.data);
        setVideoList(l);

        if (l.length < videosTotal) {
          setLoading(false);
        }

        setInitLoading(false);
      });
    }
  }




const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={onLoadMore}>load more...</Button>
        </div>
      ) : null;

  if (!data) return(<div className="siteContent"><Loader /></div>);

  return (
    <div className="siteContent">
    <Form
      layout="vertical"
      form={form}
      name="UrlEdit"
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
      onValuesChange={onFormChange}
      requiredMark={false}
      initialValues={{
        owner: data.owner,
        quality: data.quality,
        or_url: data.or_url,
        thumbnail_url: data.thumbnail_url,
        vimeo_url: data.vimeo_url,
        vimeoVideo: "Fetching Videos..."
      }}
      style={{maxWidth:"800px"}}
    >

      <Modal
        title={"Select Video"}
        visible={videoModalVisible}
        onOk={() => {}}
        onCancel={onCancel}
        footer={null}
        width={"80%"}
      >
      <List
        className="videoList"
        loading={initLoading}
        itemLayout="vertical"
        dataSource={videoList}
        loadMore={loadMore}
        style={{ maxHeight: "800px", overflow: "auto" }}
        renderItem={video => (
          <List.Item className="videoListItem">
            <Image 
              style={{ cursor: "pointer" }}
              src={video.pictures.sizes[0].link} 
              preview={false}
              onClick={() => { onCancel(); onSelectVimeoVideoChange(video.uri); }}
            />
            <div>{video.name}</div>
          </List.Item>
        )}
      />

      </Modal>


      <Button type="primary" onClick={selectVideoModal}>Select Video</Button>

      <br /><br />

      <div style={{ margin:"2em 0" }}>
        {vimeoID && (
          <Vimeo video={vimeoID} />
        )}
      </div>

      {data.name && (
      <div className="name">
        {data.name}
      </div>
      )}

      {data.description && (
      <div className="description">
        {data.description}
      </div>
      )}

      {data.tags && data.tags.length > 0 && (
      <div>
        {data.tags.map((_t, _i) => {
          return (<Tag key={_i}>{_t.tag}</Tag>);
        })}
      </div>
      )}

      <br />

      <Button disabled={!videos} href={data.vimeo_url + "/settings"} target="_blank" icon={<EditOutlined />}>
        Video Settings
      </Button>

      <Button onClick={refreshVideoData}>Refresh</Button>

      <br /><br />

      <Form.Item label="Artist" name="owner">
        <Input style={{maxWidth:"400px"}} />
      </Form.Item>

      <Form.Item name="quality" label="Playback Quality">
        <Select style={{width:"100px"}}>
          <Option value="auto">auto</Option>
          <Option value="1080p">1080p</Option>
          <Option value="2k">2k</Option>
          <Option value="4k">4k</Option>
          <Option value="8k">8k</Option>
        </Select>
      </Form.Item>

      Player URL
      <Input.Group compact>
        <Form.Item
          rules={[{ required: true, message: 'Please generate a URL' }]}
          name="or_url">
          <Input />
        </Form.Item>
        <Button icon={<LinkOutlined/>} loading={generatingURL} onClick={generateURL}>Generate</Button>
      </Input.Group>

      <br />

      <Input.Group compact>
        <Button icon={<CheckOutlined/>} loading={submitting} onClick={() => setSubmitting(true)} style={{ marginRight: '1em' }} type="primary" htmlType="submit">Save</Button>
        <Button icon={<CloseOutlined/>} danger onClick={cancelAdd}>Cancel Changes</Button>
      </Input.Group>

      <Form.Item  name="thumbnail_url">
        <Input type="hidden" name="thumbnail_url"/>
      </Form.Item>

      <Form.Item name="vimeo_url">
        <Input type="hidden" name="vimeo_url" />
      </Form.Item>

    </Form>
    </div>
  );

};

export default withRouter(UrlEdit);
