import React, { useState, useEffect }  from 'react';
import Util from './../../Util'
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Input } from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import $ from 'jquery';
import styled from 'styled-components';

import Loader from '../Loader';
import ContentRow from "./ContentRow";
import MakeRequestButton from "../MakeRequest"

const HeaderFlex = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  margin-bottom:3em;
`;

const HeaderTitle = styled.h2`
  min-width:13em;
`;

const HeaderCopy = styled.div`
  font-weight:bold;
  font-size: 1.3em;
  margin-right:3em;
  max-width:40em;
  min-width:10em;
`;

const HeaderRequest = styled.div`
max-width:14em;
`;

const ContentList = (props) => {

  const [data, setData] = useState(false);
  const user = useSelector(state => state.reducers.user);

  var debouncedFilter = Util.debounce((query) => {

    const dataTable = $(".data-table table tbody tr");

    let queryArray = [];

    queryArray = query.toLowerCase().split(",").map((str) => {
      return str.trim();
    });

    queryArray = queryArray.filter((str) => {
      return str.length > 0;
    });

    if (queryArray.length === 0) {
      dataTable.toggle(true);
    } else {
        dataTable.map((i, elem) => {
          const el = $(elem);
          let showRow = false;
          for (let i = 0; i < queryArray.length; i++) {
            const containsQuery = el.find('.filterable').text().toLowerCase().indexOf(queryArray[i]) > -1;
            showRow = containsQuery;
            if (!containsQuery) break;
          }
          el.toggle(showRow);
          return showRow;
      });
    }
  }, 250);

  const onFilter = (e) => {
    debouncedFilter(e.target.value);
  }

  useEffect(() => {
    fetch("/api/urls").then(res => res.json()).then(urls => {
      console.log(urls);
      setData(urls);
    });
  }, []);

  if (!data) return (
    <div className="siteContent">
      <Loader />
    </div>
  );

  return (
    <div className="siteContent">

    <HeaderFlex>

      <HeaderTitle>cFire Content Library</HeaderTitle>

      <HeaderCopy>
        cFire Content Library is a cloud-based platform of high-resolution video for use in large architectural video screens and interior design settings.
      </HeaderCopy>

      <HeaderRequest>
        <MakeRequestButton />
        <div style={{height:"0.5em"}}></div>
        Not seeing what you're looking for? cFire will find it!
      </HeaderRequest>

    </HeaderFlex>

    {user.authLevel === 2 && (<>
      <Button icon={<PlusOutlined />} type="primary" href="/urls/add">Add</Button>
      <br /><br />
      </>
    )}

    <Input size="large" style={{maxWidth: "350px", marginBottom: "2em"}} placeholder="Filter" allowClear onChange={onFilter} />

    <div className="data-table">
      <table className="sortable">
      <thead>
        <tr id="table-head">
          <th className="sorttable_nosort">Action</th>
          <th className="sorttable_nosort">URL</th>
          <th className="sorttable_nosort">Thumbnail</th>
          <th>Title&nbsp;</th>
          <th className="sorttable_nosort">Tags</th>
          <th className="sorttable_nosort">Description</th>
          <th>Views&nbsp;</th>
          <th>Artist&nbsp;</th>
        </tr>
       </thead>
       <tbody>
        {data.map((data, i) => <ContentRow key={i} data={data} /> )}
       </tbody>
      </table>
    </div>
    </div>
  );

};

export default withRouter(ContentList);